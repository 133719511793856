import React from "react"
import useCourses from "hooks/useCourses"
import useCourseTypes from "hooks/useCourseTypes"
import "./Steps.css"

export default function Step5(props) {

    const {getCourse} = useCourses()
    const {getType} = useCourseTypes()
    const {data} = props
    const course = getCourse(data.courseId)
    const type = getType(course.type)

    return <section className="step" id="step-5">
        <h2>Final step: Check and confirm</h2>        
        <p>Please check your data and click the 'Apply' button</p>
        
        
        <div className="inputs" id="confirm">
            <div className="field">Course:</div><div>{type.name}</div>
            <div className="field">First name:</div><div>{data.givenName}</div>
            <div className="field">Last name:</div><div>{data.familyName}</div>
            <div className="field">Birthdate:</div><div>{data.birthdate}</div>
            <div className="field">Gender:</div><div>{data.gender}</div>
            <div className="field">Nationality:</div><div>{data.nationality}</div>
            <div className="field">Email address:</div><div>{data.email}</div>
            <div className="field">Phone:</div><div>{data.phone}</div>
            <div className="field">LinkedIn profile:</div><div>{data.linkedIn}</div>
            <div className="field">Licence:</div><div>{data.licence}</div>
            <div className="field">CV:</div><div>{data.uploadFilename}</div>
            <div className="field">Notes:</div><div>{data.notes}</div>
        </div>
        
    </section>
}