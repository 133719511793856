import React from "react"
import classNames from "classnames"
import SelectCountry from "components/SelectCountry"
import "./Steps.css"

export default function Step2(props) {

    const {invalid, data, update} = props
    
    return <section className="step" id="step-2">
        <h2>Step 2 of 5: Your personal data</h2>        
        <p>We need to know basic stuff about you</p>
        
        
        <div className="inputs">        
            <label className={classNames({invalid: invalid.includes('givenName')})}>First name</label>
            <input value={data['givenName']} onChange={e => update('givenName', e.target.value)} type="text" placeholder="Given name..."/>
            
            <div>Please use your legal name, with all original characters (such as š, ć, è, ö...)</div>
            
            <label className={classNames({invalid: invalid.includes('familyName')})}>Last name</label>
            <input value={data['familyName']} onChange={e => update('familyName', e.target.value)} type="text" placeholder="Family name..."/>
            
            <label className={classNames({invalid: invalid.includes('nickname')})}>Nickname</label>
            <input value={data['nickname']} onChange={e => update('nickname', e.target.value)} type="text" placeholder="Nickname (if any)..."/>
            
            <label className={classNames({invalid: invalid.includes('birthdate')})}>Birthdate</label>
            <input value={data['birthdate']} onChange={e => update('birthdate', e.target.value)} type="date" placeholder="Your birthday..."/>
            
            <div>Applicants must be of at least 18 years of age</div>
            
            <label className={classNames({invalid: invalid.includes('gender')})}>Gender</label>
            <select value={data['gender']} onChange={e => update('gender', e.target.value)} >
                <option value="">Please select your gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
            </select>
            
            <label className={classNames({invalid: invalid.includes('nationality')})}>Nationality</label>
            <SelectCountry id="birth_country" value={data['nationality']} onChange={e => update('nationality', e.target.value)}/>

        </div>
    </section>
}