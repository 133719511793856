import React, {useState, useEffect, useMemo} from "react"
import classNames from "classnames"
import moment from "moment"
import useCourses from "hooks/useCourses"
import useCourseTypes from "hooks/useCourseTypes"
import useLocations from "hooks/useLocations"
import {isEmpty, plural} from "utils"
import {saturday, getAcademyYear} from "dates"
import "./Steps.css"


export default function Step1(props) {

    const year = getAcademyYear()
    const {data: courses, getCourse, getNext, getPrice} = useCourses()
    const {getType, data: types} = useCourseTypes()
    const {getLocation} = useLocations()
    const {invalid, data, update} = props
    const [type, setType] = useState('')

    const course = useMemo(() => {
        return getCourse(data.courseId)
    }, [data.courseId, getCourse])


    useEffect(() => {
        if (course && course.type !== type)
            setType(course.type)        
    }, [course, type])


    const selectCourses = useMemo(() => {
        return courses?.filter(course => course.open ?? true).filter(course => isEmpty(course.previous) && course.type === type)
    }, [type, courses])


    useEffect(() => {
        if (selectCourses && selectCourses.length === 1) update('courseId', selectCourses[0].id)
        else update('courseId', '')
    }, [selectCourses, update])
    
           
    const changeType = (type) => {
        setType(type)
        update('courseId', '')
    }
    
    
    return <section className="step" id="step-1">
        <h2>Step 1 of 5: Your course</h2>        
        <p>Please select the course you want to attend</p>
        
        
        <div className="inputs">
            <select value={type} onChange={e => changeType(e.target.value)}>
                <option value="">Please select the course</option>
                {types?.map(t=> <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
            
            {invalid.includes('courseId') && <div className="please-select">Please select</div>}
            
            <div id="courses">
                {selectCourses?.map(course => [course, getNext(course.id), getType(course.type)])
                    .map(([course, next, courseType]) => [course, next, courseType, getLocation(course.location), getLocation(next?.location)])
                    .map(([course, next, courseType, location, nextLocation]) => <button key={course.id} className={classNames("course", {selected: course.id === data.courseId})} onClick={e => update('courseId', course.id)}>
                        <div className="name">{courseType?.name}</div>
                        
                        <div className="columns">
                            
                            
                            {next?.week ? <>
                                <div>Duration:</div><div>{courseType.weeks} {plural('week', courseType.weeks)}</div>
                                <div>Week 1:</div>
                                <div>Starting {moment(saturday(course.week, year)).format("LL")}<br/>
                                from {location.city}, {location.country}</div>
                                <div>Week 2:</div><div>Starting {moment(saturday(next.week, year)).format("LL")}<br/>
                                from {nextLocation.city}, {nextLocation.country}</div>
                                <div>Price:</div><div>{getPrice(course)} EUR</div>
                            </> : <>
                                <div className="span-2">Duration: {courseType.weeks} {plural('week', courseType.weeks)}</div>
                                <div className="span-2">Starting {moment(saturday(course.week, year)).format("LL")}<br/>
                                from {location.city}, {location.country}</div>
                                <div className="span-2">Price: {getPrice(course)} EUR</div>
                            </>}
                            
                        </div>
                    </button>)
                }
                
                {selectCourses?.length === 0 && type !== "" && <div>
                    No courses are currently open for application.
                </div>}
            </div>
        </div>
    </section>
}