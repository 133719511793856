import React from "react"
import "./Payment.css"


export default function SuccessPage() {

    return <section>
        <h1>SailWeek Academy</h1>
        
        <h2>Success!</h2>        
        
        <p>
            We have received your payment.<br/>
            You will be receiving detailed instructions via email.
        </p>
        
        <h2>Thank you!</h2>
    </section>
}