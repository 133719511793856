import React from 'react'
import useCountries from "hooks/useCountries"

export default function SelectCountry(props) {

    const {id, value, onChange} = props
    const {countries, topCountries} = useCountries()    
    
    return <select id={id} value={value} onChange={onChange}>
        <option value="">Select country</option>
        <optgroup label="Most frequent">
            {topCountries.map(country => <option key={country.code} value={country.code}>{country.name}</option>)}
        </optgroup>
        <optgroup label="All countries">
            {countries.map(country => <option key={country.code} value={country.code}>{country.name}</option>)}
        </optgroup>
    </select>

}