import React, {useState, useCallback} from "react"
import swal from "sweetalert"
import {toast} from "react-toastify"
import {API} from "API"
import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Step4 from "./Step4"
import Step5 from "./Step5"
import Success from "./Success"
import {isEmpty} from "utils"
import "./ApplyPage.css"


const APPLY_STEP = 5
const SUCCESS_STEP = 6

export default function ApplyPage() {
    
    const [step, setStep] = useState(1)
    const [invalid, setInvalid] = useState([])
    
    const [data1, setData1] = useState({
        courseId: ""
    })
    
    const [data2, setData2] = useState({
        givenName: "",
        familyName: "",
        nickname: "",
        birthdate: "",
        gender: "",
        nationality: ""
    })
    
    const [data3, setData3] = useState({
        email: "",
        emailAgain: "",
        phone: ""
    })
    
    const [data4, setData4] = useState({
        linkedIn: "",
        licence: "",
        uploadId: null,
        uploadFilename: "",
        notes: "",
        english: false
    })
    
    const getEmpty = (data, fields) => {
        return fields.filter(field => isEmpty(data[field]))
    }
    
    const next = () => {
        const invalid = []
        if (step === 1) {
            invalid.push(...getEmpty(data1, ['courseId']))
            setInvalid(invalid)
            if (invalid.length === 0) setStep(step => step + 1)
        }
        else if (step === 2) {
            invalid.push(...getEmpty(data2, ['givenName', 'familyName', 'birthdate', 'nationality', 'gender']))
            setInvalid(invalid)
            if (invalid.length === 0) setStep(step => step + 1)
        }
        else if (step === 3) {
            invalid.push(...getEmpty(data3, ['email', 'emailAgain', 'phone']))
            if (data3.email !== data3.emailAgain) invalid.push('emailAgain')
            setInvalid(invalid)
            if (invalid.length === 0) setStep(step => step + 1)
        }
        else if (step === 4) {
            if (!data4['english']) invalid.push('english')
            if (!data4['linkedIn'] && !data4['uploadId']) invalid.push('resume')
            setInvalid(invalid)
            if (invalid.length === 0) setStep(step => step + 1)
        }        
    }
    
    const prev = () => {        
        if (step > 1) {
            setInvalid([])
            setStep(step => step - 1)
        }
    }
    
    const apply = () => {
        const application = {...data1, ...data2, ...data3, ...data4}
        delete application.emailAgain
        delete application.english
        toast.promise(
            API.applyPost({}, JSON.stringify(application)).then(response => {
                console.log("APPLIED", response)
                setStep(6)
            }).catch(response => {
                swal('error', 'Ooops, something went wrong', 'error')
            }),
            {
                pending: 'Applying...',
                success: 'Applied',
                error: "ERROR: Not applied"
            }
        )
    }
    
    const update = useCallback((field, value) => {
        setInvalid(invalid => invalid.filter(f => f !== field))
        if (step === 1) setData1(data => ({...data, [field]: value}))
        if (step === 2) setData2(data => ({...data, [field]: value}))
        if (step === 3) setData3(data => ({...data, [field]: value}))
        if (step === 4) setData4(data => ({...data, [field]: value}))
    }, [step])

    return <section id="apply">
        <div className="fields" id="apply-form">
                
            {step === 1 && <Step1 invalid={invalid} data={data1} update={update}/>}            
            {step === 2 && <Step2 invalid={invalid} data={data2} update={update}/>}
            {step === 3 && <Step3 invalid={invalid} data={data3} update={update}/>}
            {step === 4 && <Step4 invalid={invalid} data={data4} update={update}/>}
            {step === 5 && <Step5 data={{...data1, ...data2, ...data3, ...data4}}/>}
            {step === SUCCESS_STEP && <Success data={{...data1, ...data2, ...data3, ...data4}}/>}

            <div id="buttons">
                {step > 1 && step <= APPLY_STEP && <button className="btn btn-lg btn-light" onClick={prev}>Previous</button>}
                {step < APPLY_STEP && <button className="btn btn-lg btn-primary" onClick={next}>Next</button>}
                {step === APPLY_STEP && <button className="btn btn-lg btn-primary" onClick={apply}>Apply</button>}
            </div>
        </div>
    </section>
}