import countries from './countries.json'


const TOP_COUNTRIES = [
    {
        "name":"Croatia",
        "dial_code":"+385",
        "code":"HR"
    },
    {
        "name":"Serbia",
        "dial_code":"+381",
        "code":"RS"
    },
    {
        "name":"Slovenia",
        "dial_code":"+386",
        "code":"SI"
    },
    {
        "name":"United Kingdom",
        "dial_code":"+44",
        "code":"GB"
    },
    {
        "name":"United States",
        "dial_code":"+1",
        "code":"US"
    },
    {
        "name":"Netherlands",
        "dial_code":"+31",
        "code":"NL"
    }
]


export default function useCountries() {

    const getCountry = (code) => {
        for (const country of countries)
            if (country.code === code)
                return country.name
        return null
    }

    return {countries, topCountries: TOP_COUNTRIES, getCountry}
}