import {useQuery} from "react-query"
import {API} from "API"
import {getAcademyYear} from "dates"

export default function useCourses(year=getAcademyYear()) {

    const query = useQuery(
        ["courses", year],
        () => API.coursesGet({year}).then(response => response.data)
    )
    
    const getCourse = (courseId) => {
        if (!query.data) return null
        for (const course of query.data)
            if (course.id === courseId)
                return course
        return null       
    }
    
    const getPrice = (course) => {
        return course.instalments.reduce((acc, currentValue) => acc + currentValue, 0)
    }
    
    const getNext = (courseId) => {
        if (!query.data) return null
        for (const course of query.data)
            if (course.previous === courseId)
                return course
        return null     
    }

    return {...query, getCourse, getNext, getPrice}
    
}