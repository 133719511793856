import React from "react"
import {Route, BrowserRouter as Router, Routes} from "react-router-dom"
import {QueryClient, QueryClientProvider} from "react-query"
import {ToastContainer, Slide} from "react-toastify"
import MainLayout from "layout/MainLayout"
import NotFound from "layout/NotFound"
import HomePage from "pages/home/HomePage"
import ApplyPage from "pages/apply/ApplyPage"
import SuccessPage from "pages/payment/SuccessPage"
import CancelPage from "pages/payment/CancelPage"
import "react-toastify/dist/ReactToastify.css"
import 'react-phone-input-2/lib/style.css'


const queryClient = new QueryClient()

export default function App() {

    return <QueryClientProvider client={queryClient}>
        <ToastContainer position="bottom-center" autoClose={2500} hideProgressBar newestOnTop closeOnClick pauseOnFocusLoss={false} draggable pauseOnHover theme="dark" transition={Slide}/>
  		<Router>
  			<Routes>
  				<Route element={<MainLayout/>}>
  					<Route index element={<HomePage/>}/>
  					<Route path="/apply" element={<ApplyPage/>}/>
  					<Route path="/payment/success" element={<SuccessPage/>}/>
  					<Route path="/payment/cancel" element={<CancelPage/>}/>
  				</Route>
  				<Route path="*" element={<NotFound/>}/>         
  			 </Routes>                        
  		 </Router>        
    </QueryClientProvider>
    
}
