import {useQuery} from "react-query"
import {API} from "API"

export default function useCourseTypes() {

    const query = useQuery(
        ["locations"],
        () => API.locationsGet({}).then(response => response.data)
    )
    
    
    const getLocation = (id) => {
        if (!query.data) return null
        for (const location of query.data)
            if (location.id === id)
                return location
        return null       
    }

    return {...query, getLocation}
    
}