import React from "react"
import "./Payment.css"


export default function CancelPage() {

    return <section>
        <h1>SailWeek Academy</h1>
        
        <h2>Canceled</h2>        
        
        <p>
            Unfortunately, we haven't received your payment yet.<br/>
            Please give it another try, or feel free to reach out to SailWeek Academy for more help.
        </p>
    </section>
}