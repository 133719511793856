import {useQuery} from "react-query"
import {API} from "API"

export default function useCourseTypes() {

    const query = useQuery(
        ["course-types"],
        () => API.coursesTypesGet({}).then(response => response.data)
    )
    
    
    const getType = (type) => {
        if (!query.data) return null
        for (const t of query.data)
            if (t.id === type)
                return t
        return null       
    }
    
    
    const getPrice = (type) => {
        return query.data?.instalments.reduce((acc, currentValue) => acc + currentValue, 0)
    }

    return {...query, getType, getPrice}
    
}