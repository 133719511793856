import React from "react"
import {Outlet} from "react-router-dom"
import "./MainLayout.css"

export default function MainLayout() {
    
    return <div id="main-layout">
        <main>
            <Outlet/>
        </main>
    </div>


}
