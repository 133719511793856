
/** Use for filter to get unique list elements */
export function unique(value, index, self) {
    return self.indexOf(value) === index
}

/** Is dictionary, string or array empty */
export function isEmpty(obj) {
    if (obj === undefined || obj === null)
        return true
    if (typeof obj === 'string' || obj instanceof String)
        return obj.length === 0
    if (Array.isArray(obj)) 
        return obj.length === 0
    return Object.keys(obj).length === 0
}


export function isValidURL(url) {
  const urlPattern = /^(?:https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(?:\/[\w.-]*)*(?:\?[\w-]+=[\w.-]+(?:&[\w-]+=[\w.-]+)*)?(?:\/)?$/
  return urlPattern.test(url)
}

export function isValidEmail(str) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(str)
}

export function isValidDate(dateString) {
  // Create a regular expression pattern for the ISO date format
  var isoDatePattern = /^\d{4}-\d{2}-\d{2}$/

  // Check if the string matches the ISO date pattern
  if (!isoDatePattern.test(dateString))
    return false

  // Parse the date components from the string
  var year = parseInt(dateString.substr(0, 4), 10)
  var month = parseInt(dateString.substr(5, 2), 10)
  var day = parseInt(dateString.substr(8, 2), 10)

  // Create a new Date object using the parsed components
  var date = new Date(year, month - 1, day)

  // Check if the parsed date is a valid date
  return (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day)
}



/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function randInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatPhoneNumber(phone) {
    if (!phone)
        return ""
    phone = phone.trim()
    if (!phone)
        return ""
        
    for (const ch of [' ', '/', '-', '/', '(', ')']) {
        phone = phone.replaceAll(ch, '')
    }

    if (phone.startsWith('+'))
        return phone
        
    if (phone.startsWith('00'))
        return '+' + phone.substr(2)

    if (phone.startsWith('6') && phone.length < 10)
        return '+381' + phone

    if (phone.startsWith('06') && phone.length < 11)
        return '+381' + phone.substr(1)
        
    if (phone.startsWith('9') && phone.length < 10)
        return '+385' + phone

    if (phone.startsWith('09') && phone.length < 11)
        return '+385' + phone.substr(1)

    return '+' + phone
}


/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000.
 *           False to use binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export function formatFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}


export function capitalizeFirstLetter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export function romanize(num) {
    const lookup = {M:1000, CM:900, D:500, CD:400, C:100, XC:90, L:50, XL:40, X:10, IX:9, V:5, IV:4, I:1}
    var roman = ''
    for (var i in lookup) {
        while (num >= lookup[i]) {
            roman += i
            num -= lookup[i]
        }
    }
    return roman
}

export function cleanUpSpecialChars(str) {
    return str
        .replace(/[ÀÁÂÃÄÅ]/g,"A")
        .replace(/[àáâãäå]/g, "a")
        .replace(/[íìï]/g, "i")
        .replace(/[ÍÌÏ]/g, "i")
        .replace(/[ÈÉÊË]/g, "E")
        .replace(/[éè]/g, "e")
        .replace(/[ö]/g, "o")
        .replace(/[ü]/g, "u")
        .replace(/[ß]/g, "ss")                
        .replace(/[ç]/g, "c")        
        .replace(/[š]/g, "s")
        .replace(/[Š]/g, "S")
        .replace(/[Ć]/g, "C")
        .replace(/[Č]/g, "C")
        .replace(/[ć]/g, "c")
        .replace(/[č]/g, "c")
        .replace(/[đ]/g, "dj")
        .replace(/[Đ]/g, "Dj")
        .replace(/[Ž]/g, "Z")
        .replace(/[ž]/g, "z")
        .replace(/[ñ]/g, "n");
        // .replace(/[^a-z0-9]/gi,'');
}

export function plural(word, count) {
    count = parseInt(count)
    if (count === 1) return word
    if (word === 'hostess') return 'hostesses'
    return word + 's'
}

export function thirdPerson(verb, count) {
    if (verb === 'be') return count === 1 ? 'is' : 'are'
    if (verb === 'have') return count === 1 ? 'has': 'have'
    return count === 1 ? verb + 's' : verb
}

export function getUploadTypeDescription(type) {
    if (type === 'picture') return 'Profile picture'
    if (type === 'licence') return 'Sailing licence'
    if (type === 'insurance') return 'Insurance policy'
    return 'Unknown'
}

export function byRole(a, b) {
  var roleOrder = ["skipper", "hostess", "crew"]
  
  const roa = roleOrder.indexOf(a.role)
  const rob = roleOrder.indexOf(b.role)
  if (roa === rob) return 0
  if (roa < rob) return -1
  return 1
}
