import React from "react"
import classNames from "classnames"
import PhoneInput from 'react-phone-input-2'
import "./Steps.css"

export default function Step3(props) {

    const {invalid, data, update} = props
    

    return <section className="step" id="step-3">
        <h2>Step 3 of 5: Your contact data</h2>        
        <p>Let us know how to contact you</p>
        
        
        <div className="inputs">    
            <label className={classNames({invalid: invalid.includes('email')})}>Email address</label>
            <input value={data['email']} onChange={e => update('email', e.target.value)} type="text" placeholder="Email..."/>
            
            <label className={classNames({invalid: invalid.includes('emailAgain')})}>Please repeat your email</label>
            <input value={data['emailAgain']} onChange={e => update('emailAgain', e.target.value)} type="text" placeholder="To be sure..."/>
            
            <label className={classNames('phone', {invalid: invalid.includes('phone')})}>Phone number</label>
            <PhoneInput value={data['phone']} onChange={phone => update('phone', phone)} country={'us'} placeholder="Phone..."/>
        </div>
    </section>
}