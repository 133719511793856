import React from "react"
import {Link} from "react-router-dom"
import "./HomePage.css"


export default function HomePage() {

    return <section>
        <h1>SailWeek Academy</h1>
        <p>Learn. Sail. Work.</p>
        
        
        
        <section id="jedan">
            <div className="content">
                <h2>Young Professionals Training Centre</h2>
                <p>You were always wondering how to become a part of this fast growing industry? The answer is SailWeek Academy! Whether you want to become a skipper or hostess, our academies got you covered.</p>
                <ul>
                    <li>Spend your summer at sea</li>
                    <li>Become a part of the SailWeek family</li>
                    <li>Earn from 450 to 940 EUR per week + tip</li>
                    <li>Work & Sail on yachts and catamarans from 40 to 64ft length</li>
                    <li>A job all your friends are jealous of :)</li>
                </ul>
            </div>
        </section>

        
        <p>Beginner, Advanced or Catamaran</p>
        <h2>Skipper Academy</h2>
        <p>A luxury academy for the education of captains of small ships, so-called skippers. The Academy offers a wide range of expertise necessary for successful ship management. Depending on the level of readiness and previously acquired certificates, the academy develops into three groups: beginner, advanced and catamaran.</p>

        <Link to="/skipper" className="btn btn-secondary">Learn more</Link>        
        <Link to="/apply" className="btn btn-primary">Apply</Link>
        
        <hr/>
        
        <h2>Hostess Academy</h2>
        <p>Academy for the education of ship’ stewardess, so called hostesses. The job of a hostess comes down to the overall knowledge of the ship, the ship’s rules, cooking and serving, and maintaining a pleasant atmosphere among the crew and guests. SailWeek Academy therefore offers everything necessary for complete readiness to work in any conditions.</p>
        
        <Link to="/hostess" className="btn btn-secondary">Learn more</Link>
        <Link to="/apply" className="btn btn-primary">Apply</Link>
    </section>
}