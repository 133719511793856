import moment from 'moment'


export function getCurrentYear() {
    return new Date().getFullYear()
}

export function getWeek(inputDate) {
    const inputDatetime = new Date(inputDate)
    const inputWeekday = inputDatetime.getDay()

    if (inputWeekday === 0 || inputWeekday === 6) { // Saturday or Sunday
        inputDatetime.setDate(inputDatetime.getDate() + 2)
    }

    const year = inputDatetime.getFullYear()
    const month = inputDatetime.getMonth() + 1 // JavaScript months are 0-based
    const day = inputDatetime.getDate()
    
    const dateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    const date = moment(dateStr)
    return date.isoWeek()
}


export function isoFormat(date) {
    return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}


export function saturday(week, year=null) {
    if (!year) year = getCurrentYear()
    let date = moment().year(year).isoWeek(week).day('Saturday')
    if (date.isoWeek() > week) date = date.subtract(1, 'week')
    date = date.subtract(1, 'week')
    return date.toDate()  
}


export function daysTo(datum) {
    const day = moment(datum)
    const today = moment().startOf('day')
    return day.diff(today, 'days')
}


export function isValidDate(dateString) {
    var isoDatePattern = /^\d{4}-\d{2}-\d{2}$/
    if (!isoDatePattern.test(dateString))
      return false
    var year = parseInt(dateString.substr(0, 4), 10)
    var month = parseInt(dateString.substr(5, 2), 10)
    var day = parseInt(dateString.substr(8, 2), 10)
    var date = new Date(year, month - 1, day)
    return (date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day)
}


/** Vraća dict <week> -> <Saturday date> */
export function getWeeks(year) {
    year = parseInt(year)
    const weeks = {}
    let week = 1
    while (true) {
        const date = saturday(week, year)
        if (getWeek(date) !== week)
            return weeks
        weeks[week] = isoFormat(date)
        week += 1
    }
}

export function getAge(birthday) {
    if (!birthday) return null
    var birthDate = new Date(birthday)
    var ageDiff = Date.now() - birthDate.getTime()
    var ageDate = new Date(ageDiff)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}


export function getAcademyYear() {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()  // Month is zero-indexed

    if (currentMonth < 6) {
        // If current month is before July (i.e., first half of the year)
        return currentDate.getFullYear()
    } else {
      // If current month is July or later (i.e., second half of the year)
      return currentDate.getFullYear() + 1
    }
}

/** Returns the current week of year. */
export function getCurrentWeek() {
    return getWeek(new Date())
}
