import React from "react"
import nprogress from "nprogress"
import classNames from "classnames"
import {ENDPOINT} from "API"
import "./Steps.css"


const MIME_TYPES = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
]

export default function Step4(props) {

    const {data, update, invalid} = props

    
    const upload = (file) => {
    	nprogress.start()
        const url = ENDPOINT + "/upload"
        console.log("URL", url)
        console.log("FNAME", file.name)
        
        const formData = new FormData()
        formData.append('file', file, file.name)
        
        fetch(url, {
            method: "POST",
            headers: {'Authorization': 'token'},
            body: formData
        }).then(response => {
            return response.json()
        }).then(data => {
            nprogress.done()
            console.log("UPLOADED", data)
            update('uploadId', data.id)
            update('uploadFilename', file.name)
        }).catch(response => {
            nprogress.done()
            console.error("UPLOAD ERROR", response)
        })
    }   
    
    const onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            upload(file)
        }
    }


    return <section className="step" id="step-4">
        <h2>Step 4 of 5: Your background</h2>        
        <p>Some more about your and your experience</p>
        
        <div className="inputs">
            <label>LinkedIn Profile</label>
            <input value={data['linkedIn']} onChange={e => update('linkedIn', e.target.value)} type="text" placeholder="LinkedIn Profile URL..."/>
    
            <div id="resume-info" className={classNames({invalid: invalid.includes('resume')})}>
                Please provide either your LinkedIn profile or your resume, or ideally, both
            </div>
                
            <label>Upload CV</label>                    
            <input placeholder="Your resume..." type="file" accept={MIME_TYPES.join(',')} onChange={onFileChange}/>

            <label>Sailing Licence</label>
            <input value={data['licence']} onChange={e => update('licence', e.target.value)} type="text" placeholder="Your licence (if any)..."/>
    
            <label>Notes</label>
            <textarea value={data['notes']} onChange={e => update('notes', e.target.value)} placeholder="Any notes you might have..."/>

            <div class="checkbox">
                <input id="english" type="checkbox" checked={data['english']} onChange={e => update('english', e.target.checked)}/>
                <label htmlFor="english" className={classNames({invalid: invalid.includes('english')})}>I hereby confirm that I am proficient in spoken and written English</label>
            </div>
        </div>        
    </section>
}